<template>
  <ActionBarBase :selected="selected">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn @click="$emit('refresh')" v-on="on" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Обновить</span>
    </v-tooltip>

    <template v-slot:someSelected>
      <v-btn :icon="!$vuetify.breakpoint.mdAndUp" class="v-btn--hover" @click="pin" text>
        <v-icon>mdi-pin-outline</v-icon>
        <span class="d-none d-md-inline">
          {{ anyPinned ? "Открепить" : "Закрепить" }}
        </span>
      </v-btn>
      <v-btn
        :icon="!$vuetify.breakpoint.mdAndUp"
        class="v-btn--hover"
        @click="saveTo(selected)"
        text
      >
        <v-icon>mdi-bookmark-plus-outline</v-icon>
        <span class="d-none d-md-inline">
          Сохранить
        </span>
      </v-btn>
      <v-btn :icon="!$vuetify.breakpoint.mdAndUp" class="v-btn--hover" text>
        <v-icon>mdi-flag-outline</v-icon>
        <span class="d-none d-md-inline">
          Отметить
        </span>
      </v-btn>
    </template>

    <template v-slot:onlyOneSelected>
      <v-btn
        :to="`/service/objects/${selected[0].id}`"
        class="v-btn--hover"
        text
        :icon="!$vuetify.breakpoint.mdAndUp"
      >
        <v-icon>mdi-open-in-new</v-icon>
        <span class="d-none d-md-inline">
          Открыть
        </span>
      </v-btn>
    </template>

    <template v-slot:menu="{ someSelected }">
      <v-list-item link v-if="someSelected">
        Экспорт...
      </v-list-item>
    </template>

    <template v-slot:append>
      <v-btn class="v-btn--hover" v-if="$vuetify.breakpoint.mdAndUp" icon>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

      <v-btn
        :color="showInfoModel ? 'primary' : ''"
        :input-value="showInfoModel"
        @click="showInfoModel = !showInfoModel"
        icon
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
  </ActionBarBase>
</template>

<script>
import ActionBarBase from "@/components/DataTable/ActionBarBase";

export default {
  props: {
    selected: {
      default: []
    },
    showInfo: {
      default: false
    }
  },
  components: {
    ActionBarBase
  },
  computed: {
    showInfoModel: {
      set(to) {
        this.$emit("update:showInfo", to);
      },
      get() {
        return this.$props.showInfo;
      }
    },
    anyPinned() {
      return this.selected.some(el => el.pinned);
    }
  },
  methods: {
    pin() {
      const unpin = this.anyPinned;
      console.log('PIN UNIMPLEMENTED');
      if (unpin) {
        this.$emit("update:selected", []);
      }
    },
    saveTo(items) {
      this.$emit("save", items);
    }
  }
};
</script>
