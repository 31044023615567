<template>
  <div>
    <v-container fluid class="head px-3" style="max-width:1280px">
      <v-row>
        <v-col class="d-flex align-center pb-0 mt-5">
          <div class="d-flex align-baseline">
            <span class="text-h4 font-weight-medium">Пользователи</span>
          </div>

          <v-spacer />

        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="pa-0 mt-1"
      :style="($vuetify.theme.isDark || 'background:rgb(241, 243, 245); ') + ($vuetify.breakpoint.mdAndUp && 'border-radius: 12px')  "
      style="max-width:1280px"
    >
      <OCMapFilters
          ref="mapFilters"
          :filter="(data) => data.key !== 'zone' || $vuetify.breakpoint.smAndDown"
          @open="filterType = $event; $refs.searchBar.showFIlters = true"
          @input="$emit('update:filters', $event)" class="flex-md-wrap overflow-md-hidden overflow-x-auto px-4 pt-3" :color="$vuetify.theme.dark ? '' : 'white'" hide-toggle variant :value="filters"
      />
      <SearchBar
        ref="searchBar"
        :options="filters"
        @update:options="$emit('update:filters', $event)"
        :filters="dataFilters"
        :text_query.sync="query"
        :region.sync="region"
        :type-filters.sync="filterType"
      />
    </v-container>
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import SecondaryButton from "../../../components/SecondaryButton.vue";
import TabChipGroup from "../../../components/TabChipGroup.vue";
import opencity from "@/service/opencity";
import useClassifier from "@/mixins/useClassifier";
import OCLayerEditor from "@/components/OCLayerEditor.vue";
import OCMapFilters from "@/components/OCMapFilters.vue";

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        query: ""
      })
    }
  },
  components: {
    OCMapFilters,
    OCLayerEditor,
    SearchBar,
    SecondaryButton,
    TabChipGroup
  },
  methods: {
    setAdvanced(val) {
      const prev = this.filters.advanced;
      this.filters.advanced = val;
      this.$emit('update:filters', this.filters);
      if (!prev && val) {
        // this.filters.dataFilters = {};
        // this.filters.category = null;
        // this.filters.type = null;
        this.showLayerEdit = true;
        // this.$emit('update:filters', this.filters);
      } else if (prev && !val) {
        this.filters.dataFilters = {};
        this.filters.types = null;
        this.filters.category = null;
        this.filters.type = null;
        this.$emit('update:filters', this.filters);
      }
    },
    chipClick(chip) {
      if (this.filters.advanced) {
        // this.showLayerEdit = true;
        this.filterType = chip.id;
        this.$refs.searchBar.showFIlters = true;
        return;
      }
      if (chip.type === "cat") {
        this.cat = chip;
      } else {
        this.type = chip;
      }
    },
    chipClose(chip) {
      if (chip.type === "layer") {
        this.ftypes = null;
        return;
      }
      if (chip.type === "cat") {
        this.cat = null;
        this.type = null;
      } else {
        this.type = null;
      }
    }
  },
  data: () => ({
    statsInternal: null,
    rawCategories: null,
    rawTypes: null,
    showLayerEdit: false,
    filterType: 0
  }),
  mixins: [useClassifier],
  computed: {
    layerCount() {
      return this.filters.types?.length;
    },
    ftypes: {
      get() {
        if (this.filters.types) return this.filters.types;
        return this.types?.map(el => el.id);
      },
      set(val) {
        this.filters.types = val;
        this.filters.category = null;
        this.filters.type = null;
        this.$emit("update:filters", this.filters);
      }
    },
    mapRoute() {
      return {
        path: "/service/objects/map",
        query: this.$route.query
      };
    },
    cat: {
      get() {
        return this.classifier.cats.find(el => this.filters.category == el.id);
      },
      set(val) {
        this.filters.dataFilters = {};
        this.filters.category = val?.id;
        this.$emit("update:filters", this.filters);
      }
    },
    stats() {
      if (this.statsInternal) return this.statsInternal;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      opencity
        .get("/objects/stats")
        .then(res => res.json())
        .then(data => {
          this.statsInternal = data.reduce((o, el) => {
            o[el[0]] = el[1];
            return o;
          }, {});
        });
      return {};
    },
    type: {
      get() {
        return this.classifier.types.find(el => this.filters.type == el.id);
      },
      set(val) {
        this.filters.dataFilters = {};
        this.filters.type = val?.id;
        this.$emit("update:filters", this.filters);
      }
    },
    dataFilters() {
      return this.filters.dataFilters;
    },
    typeFilters: {
      get() {
        return 1;
      },
      set(val) {

      }
    },
    query: {
      get() {
        return this.filters.query;
      },
      set(val) {
        this.filters.query = val;
        this.$emit("update:filters", this.filters);
      }
    },
    region: {
      get() {
        return this.filters.zone;
      },
      set(val) {
        this.filters.zone = val;
        this.$emit("update:filters", this.filters);
      }
    },
    chips() {
      const { cat, type } = this;
      if (this.filters.advanced) return [];
      if (this.filters.types) {
        return this.classifier.types.filter(el => this.filters.types?.includes(el.id));
        // [{
        //   id: 0, title: this.filters.types.length + ' типов', type: 'layer'
        // }];
      }
      if (!cat) {
        return this.classifier.cats;
      } else if (!type) {
        const r = [cat];
        r.push(...this.types);
        return r;
      }
      return [cat, type];
    },
    types() {
      const { cat, type } = this;
      if (type) {
        return [type];
      }
      if (cat) {
        return this.classifier.types.filter(el => el.cat == cat.id);
      }
      return null;
    },
    classifier() {
      const cats = [...this.OCClassifier];

      cats.forEach(el => (el.type = "cat"));
      const types = cats.flatMap(
        el => el.objectTypes.map(t => ((t.cat = el.id), t)) //.filter(t => ((t.cat = el.id), t.active))
      );
      types.forEach(el => (el.type = "type"));

      return {
        cats,
        types
      };
    }
  }
};
</script>

<style>
.v-slide-group__content {
  padding: 4px 12px !important;
}
</style>
