<template>
  <div>
    <ObjectsHeadBlock
      v-intersect="{ handler: onHeadIntersect, options: { threshold: [0] } }"
      :filters.sync="options"
    />

    <div ref="scrollTo"></div>

    <ActionBar
      class="sticky"
      style="top: 0"
      :class="`elevation-${head_onscreen ? 0 : 1}`"
      :selected.sync="selected"
      :show-info.sync="info_show"
      @save="savetoDialog = true"
      @refresh="getDataFromApi"
    />

    <v-progress-linear
      v-if="$vuetify.breakpoint.smAndDown && loading"
      indeterminate
    ></v-progress-linear>

    <div
      class="d-flex align-stretch table-block"
      style="
        margin: auto;
        position: relative; 
        width: max-content;
        min-width: min(1280px, 100%);
        max-width: 100%; //fallback
      "
    >
      <ObjectsDataTable
        @update:items-per-page="
          options.itemsPerPage = $event;
          updateOptions(options);
        "
        :row-component="rowComponent"
        v-model="selected"
        :headers="headers"
        :items="Objects"
        :pinned="pinned"
        @click:row="onRowClick"
        @dbclick:row="onRowDbclick"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalObjects"
        v-if="$vuetify.breakpoint.mdAndUp"
      />

      <v-list width="100%" v-else two-line>
        <!-- <v-pagination class="my-4" v-model="options.page" :length="Math.ceil(totalObjects / options.itemsPerPage)" :total-visible="6"></v-pagination> -->
        <v-list-item-group
          v-model="selected"
          active-class="primary--text"
          multiple
        >
          <OCObjectListItem
            :item="item"
            :value="item"
            @contextmenu.prevent="selected = [item]"
            :to="selected.length > 0 ? null : `/service/objects/${item.id}`"
            v-for="item in Objects"
            :key="item.id"
          />
        </v-list-item-group>
        <div class="text-center pa-5" v-if="!loading && totalObjects == 0">
          Ничего не найдено.
        </div>
        <div class="pa-3 d-flex align-center" v-if="!loading && totalPages > 1">
          <!-- <v-btn width="36" @click="options.page = 1"  :disabled="options.page == 1" x-small height="36"><v-icon>mdi-chevron-double-left</v-icon></v-btn> -->
          <v-btn
            width="36"
            :class="options.page == 1 && 'hidden'"
            @click="setPage(options.page - 1)"
            @contextmenu.prevent="setPage(1)"
            :disabled="options.page == 1"
            x-small
            height="36"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <span
            >{{ (options.page - 1) * options.itemsPerPage + 1 }} -
            {{ Math.min(options.page * options.itemsPerPage, totalObjects) }} из
            {{ totalObjects }}
          </span>
          <v-spacer />
          <v-btn
            x-small
            :class="options.page == totalPages && 'hidden'"
            @click="setPage(options.page + 1)"
            @contextmenu.prevent="setPage(totalPages)"
            :disabled="options.page == totalPages"
            width="36"
            height="36"
            ><v-icon>mdi-chevron-right</v-icon></v-btn
          >
          <!-- <v-btn width="36" @click="options.page = totalPages"  :disabled="options.page == totalPages" x-small height="36"><v-icon>mdi-chevron-double-right</v-icon></v-btn> -->
        </div>
      </v-list>

      <div class="banner_container">
        <v-slide-y-transition>
          <v-alert
            v-show="selected.length > 1"
            color="amber lighten-2"
            dense
            light
          >
            <div class="d-flex align-baseline">
              <span class="mr-4">{{ selected.length }} выбрано объектов.</span>
              <v-btn small text @click="selected = Objects.concat(pinned)"
                >Выбрать все</v-btn
              >
              <v-btn small text @click="selected = []">Отмена</v-btn>
            </div>
          </v-alert>
        </v-slide-y-transition>
      </div>

      <Drawer
        :open.sync="info_show"
        :prominent="mobile"
        tile

        @item-selected="onSelected"
      >
        <v-card tile outlined>
          <div v-if="!last_selected">
            <div class="d-flex flex-row-reverse pt-2">
              <v-btn icon @click="info_show = false" width="46" height="46">
                <v-icon>$close</v-icon>
              </v-btn>
            </div>
            <div
              class="d-flex flex-column align-center text-center px-2 font-weight-medium"
              style="opacity: 0.5"
            >
              <v-icon size="128" style="opacity: 0.4">
                mdi-card-account-details
              </v-icon>
              Выберите объект для просмотра информации
            </div>
          </div>
          <OCObjectDataCard
            v-else
            hide-actions
            @filter="$router.push('?zone=object,' + last_selected.id)"
            :value="last_selected"
          />
        </v-card>
      </Drawer>
    </div>

    <SaveToDialog v-model="savetoDialog" :items="selected" />
  </div>
</template>

<script>
import Drawer from "@/components/Drawer";
import ObjectsDataTable from "../../components/DataTable/DataTableBase.vue";
import ObjectsDataRow from "./components/DataRow";
import ObjectsHeadBlock from "./components/ObjectsHeadBlock";
import ActionBar from "./components/ActionBar";

import PanelFrame from "@/components/PanelFrame";
import opencityService from "../../service/opencity";

import { datetime2 } from "@/utils/datetime";
import OCObjectListItem from "@/components/object/OCObjectListItem";
import OCObjectDataCard from "@/components/object/OCObjectDataCard";
import SaveToDialog from "@/components/object/SaveToDialog.vue";
import useClassifier from "@/mixins/useClassifier";
import {filterOptionsToQueryObject} from "@/utils/filterOptions";
import {defaultFilterDefinitions} from "@/assets/filters";

export default {
  components: {
    SaveToDialog,
    OCObjectDataCard,
    OCObjectListItem,
    Drawer,
    ObjectsDataTable,
    ObjectsHeadBlock,
    ActionBar
  },
  filters: {
    datetime2
  },
  mixins: [useClassifier],
  data: () => ({
    rowComponent: ObjectsDataRow,
    Objects: [],
    totalObjects: null,
    pinned: [],
    info_show: false,
    selected: [],
    last_selected: null,
    head_onscreen: true,
    focus_element: null,
    savetoDialog: false,
    savetoItem: null,
    loaded: false,
    page: 0,
    headers: [
      { text: "ID", value: "id", sortable: true },
      { text: "Имя", value: "fullName", sortable: true, class: "title-data" },
      { text: "Email", value: "email", sortable: false },
      // { text: "Тип", value: "objectType", sortable: true },
      // { text: "Видимость", value: "visibility", sortable: false },
      {
        text: "Дата регистрации",
        value: "registration_date",
        sortable: true,
        align: "end"
      },
      // { text: "Обновлен", value: "updatedAt", sortable: true, align: "end" }
    ],
    loading: false,
    lastPage: 0,
    lastQueryString: ""
  }),
  methods: {
    setPage(val) {
      this.options.page = val;
      this.updateOptions(this.options);
    },
    onSelected(item) {
      if (!item.value) return;
      this.last_selected = item.item;
      this.focusElement(event.path[3]);
    },
    onRowClick(item) {
      this.last_selected = item;
      if (this.selected.length <= 1) this.selected = [item];
      // this.info_show = true;
      this.focusElement(event.currentTarget);
    },
    onRowDbclick(item) {
      this.$router.push({ name: "Object", params: { id: item.id } });
    },
    focusElement(el) {
      if (el.tagName !== "TR") return;
      if (this.focus_element)
        this.focus_element.classList.remove("v-data-table__focus");
      this.$nextTick(() => {
        if (this.focus_element === el)
          this.$nextTick(() => {
            this.focus_element === el &&
              el.classList.add("v-data-table__focus");
          });
      });
      this.focus_element = el;
    },
    onHeadIntersect(e) {
      this.head_onscreen = e[0].isIntersecting;
    },
    getDataFromApi() {
      this.loading = true;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
        query,
        zone,
        dataFilters,
        category,
        type
      } = this.options;
      let region = zone;

      const sortString =
        (sortBy[0] || "updatedAt") + "," + (sortDesc[0] ? "desc" : "asc");

      let childRegions = null;
      if (region?.type === "region") {
        childRegions = [region.id];
      }
      let parentObject = null;
      if (region?.type === "object") {
        parentObject = region.id;
      }
      let types;
      if (this.options.types) types = this.options.types;
      else if (type) types = [type];
      else if (category) {
        types = this.OCClassifier
          .find(el => el.id == category)
          ?.objectTypes.map(el => el.id);
      }

      const createdUpdatedFromTo = [
        this.options.created.from,
        this.options.created.to,
        this.options.updated.from,
        this.options.updated.to
      ];

      const author = this.options.author;

      const visibility = this.options.visibility;

      const list = this.options.list;

      let dataFilters2 = Object.entries(this.options).reduce((o, el) => {
        if (!el[0].startsWith("tag~")) return o;
        return {
          ...o,
          [el[0].replace("tag~", "")]: el[1]
        };
      }, {});

      const call = (this.listPromise = opencityService
        .get('/people').then(res => res.json())
        .then(page => {
          if (call !== this.listPromise) return;
          this.Objects = page.data;
          this.totalObjects = page.data.length;
          this.loading = false;
        }));
    },
    updateOptions(opt) {
      if (opt === null) opt = {};
      let query = filterOptionsToQueryObject(opt);
      this.$router.push({ query });
    }
  },
  computed: {
    fixedHeader() {
      return this.offset > this.head_size;
    },
    mobile(prev) {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    totalPages() {
      return Math.ceil(this.totalObjects / this.options.itemsPerPage);
    },
    options: {
      get() {
        const q = this.$route.query;
        const region = (() => {
          const z = q.zone?.split(",");
          return z ? { type: z[0], id: z[1] } : null;
        })();
        const def = {
          '1,species': null,
          "1,genus.name": null,
          '1,condition': null,
          '1,crown_diam': {
            from: null,
            to: null
          },
          '1,trunk_diam': {
            from: null,
            to: null
          },
          '1,height': {
            from: null,
            to: null
          },
          'damage': {
            has: null
          },
          created: {
            from: null,
            to: null
          },
          updated: {
            from: null,
            to: null
          }
        };
        // const dataFilters = Object.entries(q).reduce((o, e) => {
        //   if (!e[0].startsWith("data-")) return o;
        //   let key = e[0].substring(5);
        //   let val = e[1];
        //   if (key.includes("~")) {
        //     key = key.split("~")[0];
        //     val = {
        //       ...o[key],
        //       [e[0].split("~")[1]]: e[1]
        //     };
        //   }
        //   o[key] = val;
        //   return o;
        // }, def);

        Object.entries(defaultFilterDefinitions).forEach(([key, val]) => {
          def[key] = val.deserialize(q[key]);
        });
        return {
          page: q.page ? Number(q.page) : 1,
          itemsPerPage: q.pageSize ? Math.min(Number(q.pageSize), 100) : 20,
          query: q.q,
          author: q.author,
          sortBy: q.sortBy ? [q.sortBy.split(",")[0]] : ["updatedAt"],
          sortDesc: q.sortBy ? [q.sortBy.split(",")[1] === "desc"] : [true],
          category: Number(q.cat) ?? null,
          types: q.types?.split(",").map(Number).filter(el => el) ?? null,
          type: q.type ? Number(q.type) : null,
          region: def.zone || opencityService.getDefaultRegion(),
          advanced: q.advanced || false,
          dataFilters: def,
          ...def
        };
      },
      set(val) {
        this.updateOptions(val);
      }
    }
  },
  created() {
    this.$emit("update:layout", PanelFrame);

    this.getDataFromApi();
  },
  watch: {
    options: {
      handler(value, prev) {
        if (this.lastPage != value.page) {
          this.lastPage = value.page;
          this.head_onscreen = true;
          this.$refs.scrollTo.scrollIntoView({ block: "nearest" });
        } else {
          this.options.page = 1;
        }
        this.getDataFromApi();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.table-block::v-deep th:first-child {
  padding-right: 0;
}

.banner_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 48px 12px;
  pointer-events: none;
  .v-alert {
    font-size: 14px;
    pointer-events: all;
    width: fit-content;
    margin: auto;
    position: sticky;
    top: 64px;
    border-radius: 20px !important;
  }
}

.round-corner {
  border-top-left-radius: 20px;
}

.v-btn--text.v-btn--hover {
  opacity: 0.7;
}
.v-btn--icon.v-btn--hover {
  opacity: 0.8;
}
.v-btn--icon,
.v-btn--text {
  &.v-btn--hover:hover {
    opacity: 1;
  }
}

.head {
  padding: 12px 14px;
}

.hidden {
  visibility: hidden;
}
</style>
